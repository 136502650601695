import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Button, message, Upload } from 'antd';
import { UserAddOutlined, UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Input as AntInput } from 'antd';

import Input from '../../Components/input';
import CustomTooltip from '../../Components/customTooltip';
import PhoneInput from 'react-phone-input-2';
import { StyledErrorMessage } from './profileSettings';
import SampleFile from '../../Assets/manger_upload_format.csv';
import UploadUsersConfirmation from './UploadUsersConfirmation';
import { StyledTable } from '../../SharedStyles';
import Uploader from './Uploader';
import { useTranslation } from 'react-i18next';
const { Search } = AntInput;

const userSchema = (t) =>
  yup.object().shape({
    first_name: yup.string().min(2, t('tooShort')).max(30, t('tooLong')).required(t('firstNameIsRequired')),
    last_name: yup.string().min(2, t('tooShort')).max(30, t('tooLong')).required(t('lastNameIsRequired')),
    email: yup.string().email().required(),
    mobile: yup.string(),
  });

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
};

const ManagerView = ({
  isManager,
  getUserList,
  createSingleUser,
  revokeAccess,
  createBulkUserEndpoint,
  dataKey,
  columns,
}) => {
  let uploadButtonRef = useRef(null);
  const userList = useSelector((store) => store.company[dataKey]);
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const profileData = useSelector((store) => store.company?.profile);
  const [data, setData] = useState('english');

  const dispatch = useDispatch();
  const history = useHistory();
  const [{ first_name, last_name, email, mobile }, setNewUser] = useState(initialUser);
  const [errors, setErrors] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [usertableData, setUserTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const createUser = () => {
    setIsLoading(true);

    userSchema(t)
      .validate({
        first_name,
        last_name,
        email,
      })
      .then(() => {
        dispatch(
          createSingleUser({
            first_name,
            last_name,
            email,
            mobile: mobile ? mobile : undefined,
            source_company: profileData?.source_company,
          }),
        )
          .then((res) => {
            dispatch(getUserList());
            setNewUser(initialUser);
            setErrors(null);
            togglePopConfirm();
            if (res?.data?.status === 'success') {
              message.success(res.data?.message);
              setIsLoading(true);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              setIsLoading(false);
              togglePopConfirm();
              return message.error(err?.response?.data?.message);
            }
            setErrors(Object.values(err.response?.data?.errors));
            message.error(err.response?.data?.message);
            setIsLoading(true);
          });
      });
  };

  const inputOnChange = (e, setFieldValue) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    setFieldValue(targetName, targetValue);
    setNewUser((state) => ({ ...state, [targetName]: targetValue }));
  };

  const mobileOnChange = (mobile, setFieldValue) => {
    setFieldValue('mobile', mobile);
    setNewUser((state) => ({ ...state, mobile }));
  };

  const onManagerSearch = (value) => {
    if (!value) {
      return setUserTableData(userList);
    }
    const filteredData = userList.filter(
      (elem) =>
        ((elem.first_name?.toLowerCase() || '') + ' ' + (elem.last_name?.toLowerCase() || '')).includes(
          value?.toLowerCase(),
        ) || elem.email?.toLowerCase().includes(value.toLowerCase()),
    );
    setUserTableData(filteredData);
  };

  const togglePopConfirm = () => setPopConfirmVisible((state) => !state);

  useEffect(() => {
    setUserTableData(userList);
  }, [userList]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch, getUserList]);

  useEffect(() => {
    if (userDetails) {
      if (!userDetails.company_master_account) history.goBack();
    }
  }, [history, userDetails]);

  const selectedLang = (item) => {
    setData(item);
  };

  const uploadUsers = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    if (file?.name?.split('.')?.at(-1) !== 'csv') {
      message.error(t('wrongFileUploaded'));
      setUploadCounter((state) => state + 1);
      return;
    }
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', data);
    formData.append('source_company', profileData?.source_company);
    try {
      const res = await axios.post(createBulkUserEndpoint, formData, config);
      dispatch(getUserList());

      if (res) message.success(t('usersAddedToList'));
      setUploadCounter((state) => state + 1);
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
      setUploadCounter((state) => state + 1);

      message.error(err.response?.data?.message);
    }
  };

  return (
    <div>
      {isManager && <p style={{ fontSize: 15, fontWeight: 600 }}>{t('uploadManagersToAnalyticsPlatform')}</p>}

      <Popconfirm
        placement="bottomLeft"
        title={
          <div>
            <CreateHeaderText>{`${t('create')}  ${isManager ? t('manager') : t('Employee')}  ${t(
              'form',
            )}`}</CreateHeaderText>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
              }}
              validationSchema={userSchema(t)}
              onSubmit={null}
            >
              {({ touched, errors, setFieldValue }) => (
                <Form>
                  <UserFormWrapper>
                    <Field id="first_name" name="first_name">
                      {({ field }) => (
                        <Input
                          id="first_name"
                          name="first_name"
                          placeholder={t('firstNameImp')}
                          {...field}
                          value={first_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.first_name && touched.first_name && (
                      <StyledErrorMessage name="first_name" component="div" />
                    )}
                    <Field id="last_name" name="last_name">
                      {({ field }) => (
                        <Input
                          id="last_name"
                          name="last_name"
                          placeholder={t('lastNameImp')}
                          {...field}
                          value={last_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.last_name && touched.last_name && <StyledErrorMessage name="last_name" component="div" />}
                    <Field id="email" name="email">
                      {({ field }) => (
                        <Input
                          id="email"
                          name="email"
                          placeholder={t('emailImp')}
                          {...field}
                          value={email}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.email && touched.email && <StyledErrorMessage name="email" component="div" />}

                    <PhoneInput
                      enableSearch
                      placeholder={t('selectCountryAndNumber')}
                      autoFormat
                      value={mobile}
                      onChange={(e) => mobileOnChange(e, setFieldValue)}
                    />
                  </UserFormWrapper>
                </Form>
              )}
            </Formik>
            {errors?.length &&
              errors.map((err) => (
                <div style={{ color: 'red' }} key={err}>
                  {err}
                </div>
              ))}
          </div>
        }
        onConfirm={createUser}
        onCancel={togglePopConfirm}
        okText={t('create')}
        cancelText={t('cancel')}
        icon={<UserAddOutlined style={{ color: '#00c8a0' }} />}
        visible={popConfirmVisible}
        okButtonProps={{
          disabled: !first_name || !last_name || !email || isLoading,
          loading: isLoading,
        }}
      >
        <CustomTooltip placement="top" title={<span>{t('createUser')}</span>}>
          <Button style={{ display: 'block', marginBottom: 10 }} type="primary" onClick={togglePopConfirm}>
            {t('create')} {isManager ? t('manager') : t('Employee')}
          </Button>
        </CustomTooltip>
      </Popconfirm>
      <ActionContainer>
        <Button onClick={() => setConfirm(true)} icon={<UploadOutlined />}>
          {t('upload')} {isManager ? t('managers') : t('Employees')}
        </Button>

        <UploadUsersConfirmation
          isManager={isManager}
          confirm={confirm}
          onCancel={() => setConfirm(false)}
          onConfirm={() => {
            setConfirm(false);
          }}
          uploadButtonRef={uploadButtonRef}
          selectedLang={selectedLang}
        />
        <SampleCsv href={SampleFile} download="manager_upload_sample.csv">
          {t('downloadSampleCSV')}
        </SampleCsv>
      </ActionContainer>
      <Uploader uploadButtonRef={uploadButtonRef} uploadUsers={uploadUsers} key={uploadCounter} />
      <SearchContainer>
        <p style={{ fontSize: 15, fontWeight: 500 }}>{t('invitedEmployeesLeaders')}</p>
        <CustomTooltip title={t('searchByNameEmail')}>
          <Search placeholder={t('searchByNameEmail')} allowClear onSearch={onManagerSearch} style={{ width: 200 }} />
        </CustomTooltip>
      </SearchContainer>
      {/* <p style={{ fontSize: 15, fontWeight: 500, marginTop: 15 }}>
        The following {isManager ? 'managers' : 'employees'} are active in your network. You can add or revoke access.
      </p> */}

      <StyledTable dataSource={usertableData || []} columns={columns} scroll={{ y: 410, x: 780 }} />
    </div>
  );
};

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateHeaderText = styled.div`
  font-size: 16px;
  color: #00c8a0;
  font-weight: 600;
`;

const SampleCsv = styled.a`
  color: #00c8a0;
  border: none;
  background: none;
  margin-left: 100px;
`;

const ActionContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  margin-top: 30px;
  align-items: center;
`;

const UserFormWrapper = styled.div`
  width: 250px;
  margin-top: 10px;
  input {
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .react-tel-input {
    width: 100%;
    .search {
      display: flex;
      width: 90%;
      input {
        color: black;
        &::placeholder {
          color: #bfbfbf;
        }
      }
    }
    .form-control {
      width: 100%;
      height: 40px;
      border-radius: 2px !important;
      color: black;
      &::placeholder {
        color: #bfbfbf;
      }
    }
  }
`;

export default ManagerView;
