import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Button, message, Upload, Select } from 'antd';
import { UserAddOutlined, UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Input as AntInput } from 'antd';

import Input from '../../Components/input';
import CustomTooltip from '../../Components/customTooltip';
import PhoneInput from 'react-phone-input-2';
import { StyledErrorMessage } from './profileSettings';
import SampleFile from '../../Assets/employee_upload_format.csv';
import UploadUsersConfirmation from './UploadUsersConfirmation';
import { StyledTable, TableColumn } from '../../SharedStyles';
import { resendinvite } from '../../Actions/company.actions';
import Uploader from './Uploader';
const { Option } = Select;
const { Search } = AntInput;

const userSchema = (t) => yup.object().shape({
  first_name: yup.string().min(2,  t("tooShort")).max(30,  t("tooLong")).required(t("firstNameIsRequired")),
  last_name: yup.string().min(2,  t("tooShort")).max(30,  t("tooLong")).required( t("lastNameIsRequired")),
  email: yup.string().email().required(),
  mobile: yup.string(),
  department: yup.string().required(),
});

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  department: null,
};

const HearbeatUploadView = ({ isManager, getUserList, createSingleUser, createBulkUserEndpoint, dataKey, columns }) => {
  const [{ first_name, last_name, email, mobile, department, lang }, setNewUser] = useState(initialUser);
  const [errors, setErrors] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  let uploadButtonRef = useRef(null);
  const userList = useSelector((store) => store.company[dataKey]);
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const heartbeat = useSelector(
    (store) => store.company?.profile?.only_heartbeat && !store.company?.profile?.is_analytics,
  );
  const selectorOptions = useSelector((store) => store.company.departments);
  const [isDepDropdownTouched, setisDepDropdownTouched] = useState(false);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [usertableData, setUserTableData] = useState([]);

  const [data, setData] = useState('english');
  
  const dispatch = useDispatch();
  const history = useHistory();

  const createUser = () => {
    setIsLoading(true);

    userSchema(t)
      .validate({
        first_name,
        last_name,
        email,
        department,
      })
      .then(() => {
        dispatch(
          createSingleUser({
            first_name,
            last_name,
            email,
            mobile: mobile ? mobile : undefined,
            heartbeat: heartbeat ? true : undefined,
            department_name: department ? department : undefined,
            language: lang || "english",
          }),
        )
          .then((res) => {
            dispatch(getUserList());
            setNewUser(initialUser);
            setErrors(null);
            togglePopConfirm();
            if (res?.data?.status === 'success') {
              message.success(res.data?.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              setIsLoading(false);
              togglePopConfirm();
              return message.error(err?.response?.data?.message);
            }
            setErrors(Object.values(err.response?.data?.errors));
            message.error(err.response?.data?.message);
            setIsLoading(false);
          });
      });
  };

  const inputOnChange = (e, setFieldValue) => {
    console.log('🚀 ~ file: employeeView.js ~ line 93 ~ inputOnChange ~ e', e);
    const targetName = e.target.name;
    const targetValue = e.target.value;

    setFieldValue(targetName, targetValue);
    setNewUser((state) => ({ ...state, [targetName]: targetValue }));
  };

  const mobileOnChange = (mobile, setFieldValue) => {
    setFieldValue('mobile', mobile);
    setNewUser((state) => ({ ...state, mobile }));
  };

  const languageOnChange = (lang, setFieldValue) => {
    setFieldValue('lang', lang);
    setNewUser((state) => ({ ...state, lang }));
  };

  const togglePopConfirm = () => setPopConfirmVisible((state) => !state);

  const onEmployeeSearch = (value) => {
    if (!value) {
      return setUserTableData(userList?.filter(({ revoke_status }) => !revoke_status));
    }
    const filteredData = userList.filter(
      (elem) =>
        (elem.name?.toLowerCase().includes(value.toLowerCase()) ||
          elem.email?.toLowerCase().includes(value.toLowerCase())) &&
        !elem.revoke_status,
    );

    setUserTableData(filteredData);
  };

  useEffect(() => {
    setUserTableData(userList?.filter(({ revoke_status }) => !revoke_status));
  }, [userList]);

  useEffect(() => {
    dispatch(getUserList());
    // dispatch(getInvitedEmployeeList());
  }, [dispatch, getUserList]);

  useEffect(() => {
    if (userDetails) {
      if (!userDetails.company_master_account) history.goBack();
    }
  }, [history, userDetails]);

  const selectedLang = (item) => {
    setData(item);
  };

  const uploadUsers = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    if (file?.name?.split('.')?.at(-1) !== 'csv') {
      message.error(t("wrongFileUploaded"));
      setUploadCounter((state) => state + 1);
      return;
    }
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', data);
    formData.append('heartbeat', heartbeat ? true : false);
    try {
      const res = await axios.post(createBulkUserEndpoint, formData, config);
      dispatch(getUserList());

      if (res) message.success(t("usersAddedToList"));
      setUploadCounter((state) => state + 1);
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
      setUploadCounter((state) => state + 1);

      message.error(err.response?.data?.message);
    }
  };

  return (
    <div>
      {isManager && <p style={{ fontSize: 15, fontWeight: 600 }}>{t("uploadManagersToAnalyticsPlatform")}</p>}

      <Popconfirm
        placement="bottomLeft"
        title={
          <div>
            <CreateHeaderText>{`${t("create")}  ${isManager ? t("manager") : t("Employee")}  ${t("form")}`}</CreateHeaderText>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
              }}
              validationSchema={userSchema(t)}
              onSubmit={null}
            >
              {({ touched, errors, setFieldValue }) => (
                <Form>
                  <UserFormWrapper>
                    <Field id="first_name" name="first_name">
                      {({ field }) => (
                        <Input
                          id="first_name"
                          name="first_name"
                          placeholder={t("firstNameImp")}
                          {...field}
                          value={first_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.first_name && touched.first_name && (
                      <StyledErrorMessage name="first_name" component="div" />
                    )}
                    <Field id="last_name" name="last_name">
                      {({ field }) => (
                        <Input
                          id="last_name"
                          name="last_name"
                          placeholder={t("lastNameImp")}
                          {...field}
                          value={last_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.last_name && touched.last_name && <StyledErrorMessage name="last_name" component="div" />}
                    <Field id="email" name="email">
                      {({ field }) => (
                        <Input
                          id="email"
                          name="email"
                          placeholder={t("emailImp")}
                          {...field}
                          value={email}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.email && touched.email && <StyledErrorMessage name="email" component="div" />}
                    <Field id="department" name="department">
                      {({ field }) => {
                        return (
                          <StyledSelect
                            getPopupContainer={(trigger) => trigger.parentElement}
                            bordered={false}
                            id="department"
                            name="department"
                            placeholder={t("selectDepartmentImp")}
                            {...field}
                            onBlur={() => setisDepDropdownTouched(true)}
                            value={department}
                            onChange={(_, e) =>
                              inputOnChange({ target: { name: 'department', value: e.children } }, setFieldValue)
                            }
                          >
                            {selectorOptions?.map((option) => (
                              <Option key={option.id} value={option.id}>
                                {option.name}
                              </Option>
                            ))}
                          </StyledSelect>
                        );
                      }}
                    </Field>
                    {isDepDropdownTouched && !department && (
                      <span style={{ color: 'red' }}>{t("departmentIsRequired")}</span>
                    )}
                    <PhoneInput
                      enableSearch
                      placeholder={t("selectCountryAndNumber")}
                      autoFormat
                      value={mobile}
                      onChange={(e) => mobileOnChange(e, setFieldValue)}
                    />
                    <StyledSelect
                      getPopupContainer={(trigger) => trigger.parentElement}
                      bordered={true}
                      style={{ marginTop: '6px' }}
                      defaultValue="english"
                      onChange={(e) => languageOnChange(e, setFieldValue)}
                      placeholder="Please select language"
                      options={[
                        {
                          label: t('English'),
                          key: '1',
                          value: 'english',
                        },
                        {
                          label: t('Serbian'),
                          key: '2',
                          value: 'serbian',
                        },
                        {
                          label: t('Greek'),
                          key: '3',
                          value: 'greek',
                        },
                        {
                          label: t('Arabic'),
                          key: '4',
                          value: 'arabic',
                        },
                      ]}
                    ></StyledSelect>
                  </UserFormWrapper>
                </Form>
              )}
            </Formik>
            {errors?.length &&
              errors.map((err) => (
                <div style={{ color: 'red' }} key={err}>
                  {err}
                </div>
              ))}
          </div>
        }
        onConfirm={createUser}
        onCancel={togglePopConfirm}
        okText={t("create")}
        cancelText={t("cancel")}
        icon={<UserAddOutlined style={{ color: '#00c8a0' }} />}
        visible={popConfirmVisible}
        okButtonProps={{
          disabled: !first_name || !last_name || !email || !department || isLoading,
          loading: isLoading,
        }}
      >
        <CustomTooltip placement="top" title={<span>{t("createUser")}</span>}>
          <Button style={{ display: 'block', marginBottom: 10 }} type="primary" onClick={togglePopConfirm}>
          {t("create")} {isManager ? t("manager") : t('Employee')}
          </Button>
        </CustomTooltip>
      </Popconfirm>
      <ActionContainer>
        <Button onClick={() => setConfirm(true)} icon={<UploadOutlined />}>
        {t("upload")} {isManager ? t("managers") : t('Employees')}
        </Button>

        <UploadUsersConfirmation
          isManager={isManager}
          confirm={confirm}
          onCancel={() => setConfirm(false)}
          onConfirm={() => {
            setConfirm(false);
          }}
          uploadButtonRef={uploadButtonRef}
          selectedLang={selectedLang}
        />
        <SampleCsv href={SampleFile} download="employee_upload_sample.csv">
        {t("downloadSampleCSV")}
        </SampleCsv>
      </ActionContainer>
      <Uploader uploadButtonRef={uploadButtonRef} uploadUsers={uploadUsers} key={uploadCounter} />

      <SearchContainer>
        <p style={{ fontSize: 15, fontWeight: 500, marginTop: 15 }}>
        {t("thefollowing")} {isManager ? t("managers") : t('employees')} {t("activeInYourNetwork")}
        </p>
        <CustomTooltip title={t("searchByNameEmail")}>
          <Search placeholder={t("searchByNameEmail")} allowClear onSearch={onEmployeeSearch} style={{ width: 200 }} />
        </CustomTooltip>
      </SearchContainer>
      <div style={{ marginTop: 20 }}>
        <p style={{ fontSize: 15, fontWeight: 500 }}>{t("registeredEmployees")}</p>
        <StyledTable dataSource={usertableData || []} columns={columns} scroll={{ y: 622 }} />
      </div>
    </div>
  );
};

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  // font-size: 11px;
  // display: inline-block;
  margin-bottom: 6px;
  border: 1px solid #c4c4c4 !important;
  width: 100%;
  .ant-select-borderless {
    // border: 1px solid #c4c4c4 !important;
    // width: 100%;
  }

  // .ant-select-selector {
  //   padding: 0 !important;
  //   border: 1px solid #cdcdcd !important;
  //   color: #575d7a;
  // }

  // .ant-select-selection-item {
  //   padding-right: 24px !important;
  // }
`;

const CreateHeaderText = styled.div`
  font-size: 16px;
  color: #00c8a0;
  font-weight: 600;
`;

const SampleCsv = styled.a`
  color: #00c8a0;
  border: none;
  background: none;
  margin-left: 100px;
`;

const ActionContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  margin-top: 30px;
  align-items: center;
`;

const UserFormWrapper = styled.div`
  width: 250px;
  margin-top: 10px;
  input {
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .react-tel-input {
    width: 100%;
    .search {
      display: flex;
      width: 90%;
      input {
        color: black;
        &::placeholder {
          color: #bfbfbf;
        }
      }
    }
    .form-control {
      width: 100%;
      height: 40px;
      border-radius: 2px !important;
      color: black;
      &::placeholder {
        color: #bfbfbf;
      }
    }
  }
`;

export default HearbeatUploadView;
