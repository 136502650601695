import React, { useState } from 'react';
import { Modal } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormGroup from './formGroup';
import Input from './input';
import StyledButton from './button';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getAQuote, upgradePlan } from '../Actions/company.actions';

const UgradeModal = (props) => {
  const { type, keyName, isOpen, closeModal } = props;

  const { t } = useTranslation();
  const [isSubmitted, setSubmitted] = useState(false);

  const userProfile = useSelector((store) => store.company.profile);
  const dispatch = useDispatch();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t('emailIsRequired')),
    email: Yup.string().trim().email(t('emailIsInvalid')).required(t('emailIsRequired')),
    mobile: Yup.string().matches(phoneRegExp, t('phoneNumberIsNotValid')).required(t('required')),
  });

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const paylaod = {
      user_id: userProfile?.id,
      email: values?.email,
      mobile: values?.mobile,
      name: values?.name,
      upgrade_requested: keyName,
    };
    dispatch((type === 'upgrade' ? upgradePlan : getAQuote)(paylaod))
      .then((res) => {
        if (type === 'upgrade') {
          setSubmitted(true);
        } else {
          setSubmitted(true);
          // closeModal();
        }
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Modal
      bodyStyle={{ padding: 20, minHeight: '30vh' }}
      width={500}
      title={t(type)} // upgrade | getQuote
      visible={isOpen}
      onCancel={closeModal}
      footer={null}
    >
      {isSubmitted ? (
        <Container>
          <Message>
            {type === 'upgrade'
              ? 'Thanks for the inquiry. Our Account Manager will be contacting you in 24 hours'
              : 'Your request has been submitted successfully, We will get back to you.'}
          </Message>
          <StyledButton
            style={{
              alignSelf: 'flex-end',
            }}
            onClick={closeModal}
            type="primary"
          >
            {t('ok')}
          </StyledButton>
        </Container>
      ) : (
        <Formik
          initialValues={{
            name: userProfile?.company_name,
            email: userProfile?.email,
            mobile: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormGroup>
                <HeaderNameStyled>{t('name')}</HeaderNameStyled>
                <Field id="name" name="name">
                  {({ field }) => (
                    <Input
                      id="name"
                      name="name"
                      placeholder={t('name')}
                      type="name"
                      className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage name="name" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <HeaderNameStyled>{t('emailAddress')}</HeaderNameStyled>
                <Field id="email" name="email">
                  {({ field }) => (
                    <Input
                      id="email"
                      name="email"
                      placeholder={t('emailAddress')}
                      type="email"
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <HeaderNameStyled>{t('mobile')}</HeaderNameStyled>
                <Field id="mobile" name="mobile">
                  {({ field }) => (
                    <Input
                      id="mobile"
                      name="mobile"
                      placeholder={t('mobile')}
                      type="mobile"
                      className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}
                      {...field}
                    />
                  )}
                </Field>
                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
              </FormGroup>

              <StyledButton loading={isSubmitting} type="primary" htmlType="submit">
                {t(type)}
              </StyledButton>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
export default UgradeModal;

const HeaderNameStyled = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #00c8a0;
  margin: 0px;
`;

const Message = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #20c8a0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 260px;
`;
